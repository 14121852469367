
<div class="footer-fix">
    <ul class="footer-fix__list">

        <li>
            <i class="fa-solid fa-house-user"></i>
            <a routerLink="/">Home</a>
        </li>
        <li><i class="fa-brands fa-slideshare"></i>
            <a href="#">Stories</a>
        </li>
        <li><i class="fa-solid fa-headset"></i>
            <a href="#">Ads</a>
        </li>
    </ul>
    </div>