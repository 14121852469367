import { Component } from '@angular/core';

@Component({
  selector: 'app-subscriber',
  standalone: true,
  imports: [],
  templateUrl: './subscriber.component.html',
  styleUrl: './subscriber.component.css'
})
export class SubscriberComponent {

  isVisible:boolean = false;
  getPopupStatus:string | null | undefined ;
 
  constructor(){
   
  if(typeof localStorage !=='undefined'){
    this.getPopupStatus=localStorage.getItem("popup");
    if(this.getPopupStatus === 'closed'){
      this.isVisible = false
    }
    else{
   
      this.isVisible = true
      
    }
  }
  }
 
    closePopu(){
      if(typeof localStorage !== 'undefined'){
      localStorage.setItem("popup",'closed');
      }
      this.isVisible = false
       
   
}
}
