<div id="sidebar-wrapper" style="position: relative; overflow: visible; box-sizing: border-box; min-height: 1px;">

    <div class="theiaStickySidebar"
        style="padding-top: 0px; padding-bottom: 1px; position: static; transform: none; top: 0px; left: 974.5px;">
        <div class="sidebar1 templateify-section section" id="sidebar" name="Sidebar">
            <div class="widget LinkList" data-version="2" id="LinkList1">
                <div class="widget-title title-wrap">
                    <h3 class="title">Follow Us</h3>
                </div>
                <div class="widget-content">

                </div>

            </div>
            <div class="widget PopularPosts" data-version="2" id="PopularPosts2">
                <div class="widget-title title-wrap">
                    <h3 class="title">Popular Posts</h3>
                </div>
                <div class="widget-content side-items">
                    @for(post of popularPostArray.slice(0,1); track post.id){
                    <div class="side-item cs item-0">
                        <a class="entry-inner" routerLink="/{{post.post_cat}}/{{post.post_url}}" title="{{post.post_title}}">
                            <span class="entry-image-wrap before-mask is-image"><span
                                    class="entry-image templateify-lazy"
                                    style="background-image:url(http://livenewsinhindi.com/demo/post/{{post.post_img}})"></span>
                            </span>
                            <div class="entry-header entry-info">
                                <span class="entry-category">{{post.post_cat}}</span>
                                <h2 class="entry-title">{{post.post_title}}</h2>
                                <div class="entry-meta"><span class="entry-author mi"><span class="sp">by</span><span
                                            class="author-name">{{post.post_author}}</span></span><span
                                        class="entry-time mi"><span class="sp">-</span><time class="published"
                                            datetime="2023-03-18T22:13:00-07:00">{{post.post_day|postdate}}</time>
                                        &nbsp; &nbsp; Views({{post.post_view|postviewpipe}})</span></div>
                            </div>
                        </a>
                    </div>
                    }@empty {
                        <div>No data...</div>
                    }

                    @for(post of popularPostArray.slice(0,3); track post.id){
                    <div class="side-item item-1">
                        <a class="entry-image-wrap sz-1 is-image" routerLink="/{{post.post_cat}}/{{post.post_url}}"
                            title="{{post.post_title}}"><span class="entry-image templateify-lazy"
                                style="background-image:url(http://livenewsinhindi.com/demo/post/{{post.post_img}})"></span>
                        </a>
                        <div class="entry-header">
                            <p>{{post.post_cat}} </p>
                            <h2 class="entry-title"><a routerLink="/{{post.post_cat}}/{{post.post_url}}"
                                    title="{{post.post_title}}">{{post.post_title}}</a></h2>
                            <div class="entry-meta"><time class="published"
                                    datetime="2023-03-18T22:13:00-07:00">{{post.post_day|postdate}}</time> &nbsp; &nbsp;
                                Views({{post.post_view|postviewpipe}})</div>
                        </div>
                    </div>
                }@empty {
                    <div>No data..</div>
                }

                </div>
            </div> 

            <div class="widget HTML" data-version="2" id="HTML19">
                <div class="widget-title title-wrap">
                    <h3 class="title">Subscribe Us</h3>
                </div>
                <div class="widget-content">
                    <div class="videoWrapper">
                        
                       
                    </div>
                   
                </div>
            </div>
           
            <div class="widget PopularPosts" data-version="2" id="PopularPosts2">
                <div class="widget-title title-wrap">
                    <h3 class="title">Latest Job News</h3>
                </div>
                <div class="widget-content side-items">
                    @for(post of jobPopularPostArray.slice(0,1); track post.id){
                    <div class="side-item cs item-0">
                        <a class="entry-inner" routerLink="/{{post.post_cat}}/{{post.post_url}}" title="{{post.post_title}}">
                            <span class="entry-image-wrap before-mask is-image"><span
                                    class="entry-image templateify-lazy"
                                    style="background-image:url(http://livenewsinhindi.com/demo/post/{{post.post_img}})"></span>
                            </span>
                            <div class="entry-header entry-info">
                                <span class="entry-category">{{post.post_cat}}</span>
                                <h2 class="entry-title">{{post.post_title}}</h2>
                                <div class="entry-meta"><span class="entry-author mi"><span class="sp">by</span><span
                                            class="author-name">{{post.post_author}}</span></span><span
                                        class="entry-time mi"><span class="sp">-</span><p>
                                            {{post.post_day|postdate}}</p>
                                        &nbsp; &nbsp; Views({{post.post_view|postviewpipe}})</span></div>
                            </div>
                        </a>
                    </div>
                }@empty{
                    <div>No data..</div>
                }


                @for(post of jobPopularPostArray.slice(0,3); track post.id){
                    <div class="side-item item-1">
                        <a class="entry-image-wrap sz-1 is-image" routerLink="/{{post.post_cat}}/{{post.post_url}}"
                            title="{{post.post_title}}"><span class="entry-image templateify-lazy"
                                style="background-image:url(http://livenewsinhindi.com/demo/post/{{post.post_img}})"></span>
                        </a>
                        <div class="entry-header">
                           
                            <h2 class="entry-title"><a routerLink="/{{post.post_cat}}/{{post.post_url}}"
                                    title="{{post.post_title}}">{{post.post_title}}</a></h2>
                            <div class="entry-meta"><time class="published"
                                 >{{post.post_day|postdate}}</time> &nbsp; &nbsp; 
                                Views {{post.post_view|postviewpipe}}</div>
                        </div>
                    </div>
                }@empty{
                    <div>No data..</div>
                }

                </div>
            </div>

           
            <div class="widget HTML type-comments" data-version="2" id="HTML5">
                <div class="widget-title title-wrap">
                    <h3 class="title">Comments</h3>
                </div>
                <div class="widget-content">
                    @for(comment of commentArray; track comment.id){
                    <div class="cmm1-items">
                        <div class="cmm1-item item-0"><a class="entry-inner"
                               routerLink="post/{{comment.cmt_url}}"
                               >
                                <div class="entry-header">
                                    
                                    <h2 class="entry-title cmm-title">{{comment.cmt_name}}</h2>
                                    <p class="cmm-snippet excerpt">{{comment.cmt_time}} {{comment.cmt_date}}</p>
                                    <p class="cmm-snippet excerpt">{{comment.cmt_message}}</p>
                                </div>
                            </a></div>
                       
                    </div>   }@empty{
                        <div>No data..</div>
                    }

                </div>
            </div>


        </div>
    </div>
</div>