import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PostdatePipe } from '../postdate.pipe';
import { ViewportScroller } from '@angular/common';
// declare var $:any;
@Component({
  standalone: true,
  imports: [RouterLink, DatePipe, PostdatePipe],
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  postReadUrl = "http://livenewsinhindi.com/demo/post/read.php";
  postArrayData: any = [];


  //news category post show
  newsReadUrl = "http://livenewsinhindi.com/demo/post/news.php";
  newsArrayData: any = [];

  //Technology category post show
  techReadUrl = "http://livenewsinhindi.com/demo/post/tech.php";
  techArrayData: any = [];

  //Job category post show
  jobReadUrl = "http://livenewsinhindi.com/demo/post/job.php";
  jobArrayData: any = [];

  //Busines category post show
  busiReadUrl = "http://livenewsinhindi.com/demo/post/business.php";
  busiArrayData: any = [];

  //Health category post show
  healthReadUrl = "http://livenewsinhindi.com/demo/post/health.php";
  healthArrayData: any = [];

  //science category post show
  scienceReadUrl = "http://livenewsinhindi.com/demo/post/science.php";
  scienceArrayData: any = [];

  constructor(private http: HttpClient, private viewportscroller: ViewportScroller) {

    this.http.get(this.postReadUrl, { responseType: 'json' }).subscribe((data: any) => {
      this.postArrayData = data;
      this.scrolltop()
    })

    //news category post show
    this.http.get(this.newsReadUrl, { responseType: 'json' }).subscribe((data: any) => {
      this.newsArrayData = data;



    })

    //news category post show
    this.http.get(this.techReadUrl, { responseType: 'json' }).subscribe((data: any) => {
      this.techArrayData = data;

    })

    //Job category post show
    this.http.get(this.jobReadUrl, { responseType: 'json' }).subscribe((data: any) => {
      this.jobArrayData = data;

    })

    //Business category post show
    this.http.get(this.busiReadUrl, { responseType: 'json' }).subscribe((data: any) => {
      this.busiArrayData = data;

    })
    //Health category post show
    this.http.get(this.healthReadUrl, { responseType: 'json' }).subscribe((data: any) => {
      this.healthArrayData = data;

    })

    //Science category post show
    this.http.get(this.scienceReadUrl, { responseType: 'json' }).subscribe((data: any) => {
      this.scienceArrayData = data;

    })

  }
  scrolltop() {

    this.viewportscroller.scrollToPosition([0,0])
  }

}
