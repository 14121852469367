<div>
    

    <app-verticalbothads></app-verticalbothads>
<app-navbar></app-navbar>
<div class="thetop"></div>
<div class="flex-c" id="content-wrapper">
    <div class="container row-x11 flex-sb1" style="transform: none;">
        <div class="row">
        <div class="col-md-8 col-xs-12 p-0">
            <router-outlet></router-outlet></div>
        <div class="col-md-4 p-0"><app-aside></app-aside></div>
    </div>
</div>
</div>
<app-footer></app-footer>
<app-footerfix></app-footerfix>
</div>
