import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PostdatePipe } from '../../postdate.pipe';
import { TopComponent } from '../../top/top.component';
import { GeneralComponent } from '../general/general.component';
import { TopmenuComponent } from '../topmenu/topmenu.component';
import { DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
declare var $:any;
@Component({
  standalone:true,
  imports:[PostdatePipe,TopComponent,GeneralComponent,TopmenuComponent,DatePipe,RouterLink],
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit{
  userArrayData:any=[];
  user: any; 
  date = new Date();
  
 constructor(private router:Router){
 
}
ngOnInit(): void {
  
  $(window).bind('scroll', function() {
    var navHeight = $( window ).height() - 400;
      if ($(window).scrollTop() > navHeight) {
        $('.top_main_header').addClass('fixed');
      }
      else {
        $('.top_main_header').removeClass('fixed');
      }
   });
}


}