<div>
<div class="thetop"></div>
  
<app-navbar></app-navbar>
<section class="section1">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div>
            <h6 class="firstp">WHO WE ARE</h6>
            <h1> Informative & Engaging Content Online <br>
              News Platform</h1>
            <p class="smallpera">
              Welcome to livenewsinhindi.com, a dynamic platform dedicated to delivering diverse and engaging content across
               a wide range of categories. From health 
              and food to technology, business, and entertainment, we strive to keep you informed, entertained, and inspired.
            </p>
            <p class="smallpera">
              Our team of passionate writers, journalists, and experts curates content that resonates with our readers, 
              providing insightful perspectives, latest updates,
               and in-depth analysis on topics that matter most to you.
            </p>
            <p class="smallpera">
              At livenewsinhindi.com, we believe in the power of information to educate, empower, and connect people from all walks of life. 
              Join us on this exciting journey as we explore new horizons, share valuable insights, and foster a vibrant community of knowledge seekers.
            </p>
          </div>
          <hr>
          <div class="small_box">
            <div class="row">
              <div class="col-md-6 signature">
                <div class="cont1">
                  <img src="http://balindra.com/post/images/img/signature.jpg" alt="">
                  <h6>Balindra Kumar</h6>
                  <span>CEO, Livenewsinhindi</span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="cont2">
                  <p class="mb-2">Email us to ask any question</p>
                  <p><a href="mailto:balindrakumar033@gmail.com"><strong>Mail Us for Any Query</strong></a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="text-right cylinderimg">
            <img src="/assets/img/livenewsinhindi-about.jpg" alt="livenewsinhindi about us" class="img-fluid">
            <div class="sqareimg">
              <img src="/assets/img/about-us.jpg" alt="About Us Livenewsinhindi" class="img-fluid">
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>

  <section class="section2 bg-white">
    <h6 class="text-center">REASON TO CHOOSE US</h6>
    <h2 class="text-center">Discover Engaging Content Across News, Health, Food, Technology, <br/> Business, and More</h2>
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-4 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="icon">
                <i class="fa-solid fa-newspaper"></i>
                <h5 class="card-title">NEWS</h5>
              </div>
            
               <ul class="list-bullets">
                <li> Stay informed with the latest breaking news from around the globe, covering politics, economy, and current affairs.</li>
                <li>Dive in-depth news analysis, opinion pieces, and exclusive interviews with key figures shaping the news landscape.</li>
                <li>Explore  trending news stories in entertainment, sports, and culture, keeping you updated on what's hot and happening.</li>
                <li>Our Our reliable news coverage adheres to strict journalistic standards, ensuring accuracy, objectivity, and transparency.</li>
                  
                <li>Join our community of informed readers and engage in discussions on pressing issues and impactful events.</li>
              
               </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="icon">
                <i class="fa fa-cogs" aria-hidden="true"></i>
                <h5 class="card-title">TECHNOLOGY</h5>
              </div>
            
              <ul class="list-bullets">
                <li> Discover the latest advancements in technology, from artificial intelligence and blockchain to space exploration and quantum computing.</li>
                <li>Get insights into tech trends, product reviews, and industry updates that shape the future of innovation.</li>
                <li>Learn about cutting-edge gadgets, software developments, and tech solutions driving digital transformation.</li>
                <li>Our tech articles provide practical tips, tutorials, and expert advice for navigating the digital world.</li>
                  
                <li>Stay ahead in the tech landscape with our comprehensive coverage of emerging technologies and disruptive innovations.</li>
              
               </ul>
              
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="icon">
                <i class="fa fa-users" aria-hidden="true"></i>
                <h5 class="card-title">JOBS</h5>
              </div>
            
              <ul class="list-bullets">
                <li> Explore career opportunities across various industries, with job listings, tips for job seekers, and career advice articles</li>
                <li>Get insights into job market trends, salary expectations, and skills in demand to enhance your career prospects.</li>
                <li>Discover strategies for job hunting, resume writing, and acing interviews for your dream job.</li>
                <li>Connect with recruiters, employers, and fellow professionals through networking events and job fairs.</li>
                  
                <li>Our job resources cater to both entry-level and experienced professionals, helping you take the next step in your career journey.</li>
              
               </ul>
             
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="icon">
                <i class="fa fa-hospital" aria-hidden="true"></i>
                <h5 class="card-title">HEALTH</h5>
              </div>
              
              <ul class="list-bullets">
                <li> Take charge of your well-being with expert advice on nutrition, fitness, mental health, and preventive care.</li>
                <li>Stay updated on the latest health research, medical breakthroughs, and treatments for various health conditions.</li>
                <li>Explore wellness tips, healthy recipes, and lifestyle changes to improve your overall health and vitality.</li>
                <li>Our health articles address common health concerns, debunk myths, and promote evidence-based practices for a healthier life.</li>
                  
                <li>Join our community of health enthusiasts and share your journey towards better health and wellness.</li>
              
               </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="icon">
                <i class="fa-solid fa-bullseye"></i>
                <h5 class="card-title">MEDITATION</h5>
              </div>
             
              <ul class="list-bullets">
                <li>Experience inner peace and mindfulness with guided meditation sessions tailored to reduce stress and promote relaxation.</li>
                <li>Explore different meditation techniques, such as mindfulness meditation, breathing exercises, and visualization practices.</li>
                <li>Learn about the benefits of meditation for mental health, emotional well-being, and overall stress management.</li>
                <li>Dive into spiritual meditation practices, including yoga nidra, loving-kindness meditation, and chakra balancing.</li>
                  
                <li>Join our meditation community for support, inspiration, and resources to cultivate a regular meditation practice.</li>
              
               </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="icon">
                <i class="fa fa-briefcase" aria-hidden="true"></i>
                <h5 class="card-title">BUSINESS</h5>
              </div>
              
              <ul class="list-bullets">
                <li>Gain valuable insights into business strategies, leadership skills, and entrepreneurial success stories from industry experts.</li>
                <li>Stay updated on market trends, economic forecasts, and global business developments impacting industries worldwide.</li>
                <li>Explore business growth strategies, marketing tactics, and financial management tips for small businesses and startups.</li>
                <li>Learn about digital transformation, e-commerce trends, and technology innovations driving business competitiveness.</li>
                  
                <li>Connect with fellow business professionals, network with like-minded individuals, and access resources for professional development.</li>
              
               </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="icon">
                <i class="fa fa-snowflake" aria-hidden="true"></i>
                <h5 class="card-title">SCIENCE</h5>
              </div>
             
              <ul class="list-bullets">
                <li>Discover the wonders of science, from astronomy and physics to biology, chemistry, and environmental science.</li>
                <li>Stay informed about scientific breakthroughs, research discoveries, and advancements in technology and engineering.</li>
                <li>Learn about scientific principles, experiments, and theories that shape our understanding of the natural world.</li>
                <li>Explore the intersection of science and society, including ethics, sustainability, and the impact of scientific developments.</li>
                  
                <li>Engage in discussions on hot topics in science, such as climate change, space exploration, genetics, and health sciences.</li>
              
               </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="icon">
                <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                <h5 class="card-title">EDUCATION</h5>
              </div>
             
              <ul class="list-bullets">
                <li>Access educational resources, online courses, and learning platforms to enhance your knowledge and skills.</li>
                <li>Explore topics in higher education, academic research, and innovative teaching methods for educators and students.</li>
                <li>Learn about educational technology, digital learning tools, and e-learning trends transforming the education sector.</li>
                <li>Discover career pathways in education, including teaching opportunities, educational leadership roles, and professional development options.</li>
                  
                <li>Join our community of lifelong learners, educators, and education enthusiasts passionate about continuous learning and growth.</li>
              
               </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</div>