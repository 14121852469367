
         
  <div class="card">
    <div class="card-header"> <h3 class="text-uppercase">Welcome to Post Area</h3>
    </div>
    <div class="card-body">
    <form  [formGroup]="postArticle" (ngSubmit)="postArticleSubmit()" method="post" enctype="multipart/form-data">
    <div class="text-black">
     
      <div class="row">

          <div class="row">
        <div class="col-md-6">
          <label *ngIf="language=='english'" class="form-label" >Select post category</label>
          <label *ngIf="language=='hindi'" class="form-label">पोस्ट का केटेगरी चुनिए?</label>
          <div> 
            <!-- formControlName="post_cat" -->
            <select  formControlName="post_cat" class="form-control" name="type">
              <option value="" disabled selected="selected">-- Select Event Type --</option>
            
              <option value="news">News</option>
              <option value="business">Business</option>
              <option value="technology">Technology</option>
              <option value="blog">Blog</option>
              <option value="entertainment">Entertainment</option>
              <option value="sports">Sports</option>
              <option value="science">Science</option>
              <option value="health">Health</option>
              <option value="education">Education</option>
              <option value="job">Job</option>
              <option value="politics">Politics</option>
              <option value="market">Market</option>
              <option value="meditation">Meditation</option>
              <option value="lifestyle">LifeStyle</option>
              <option value="food">Food</option>
              <option value="software">Software</option>
              <option value="website">Website</option>
              <option value="game">Game</option>
              <option value="moviesongs">Top Movie & Song</option>
              <option value="food">Food</option>
              
             
            </select>
            </div>

        </div>
        
        <div class="col-md-6">
          <div class="form-outline">
            <label *ngIf="language=='english'" class="form-label" >Select Language</label>
             <label *ngIf="language=='hindi'" class="form-label">भाषा चुने</label>
            <label class="form-label" for="form3Example1m">Select Language</label>
            <select class="form-control" formControlName="lang_cat" (change)="onChange($event.target)">
              <option value="" disabled selected="selected">-- Select Event Type --</option>
              <option value="english">English</option>
              <option value="hindi">Hindi (हिंदी)</option>
              </select>
            
            </div>
        </div>
    </div>

     
        <div class="col-md-12">
            <div class="form-outline">
                <label *ngIf="language=='english'" class="form-label">Post Title </label>
                <label *ngIf="language=='hindi'" class="form-label">अपना पोस्ट का टाइटल रखिए?</label>
                <input maxlength="70" type="text" formControlName="post_title" class="form-control"  (keyup)="onInputPost()" [(ngModel)]="post_title"/>
               
            </div>
          <div class="form-outline">
            <label *ngIf="language=='english'" class="form-label" >Write the description of the title in 160 = {{box2.value.length}} characters?</label>
            <label *ngIf="language=='hindi'" class="form-label">टाइटल का डिस्क्रिप्शन 160 = {{box2.value.length}} अक्षर में लिखे?</label>
            <input #box2 (keyup)="0" maxlength="170" type="text" formControlName="post_meta_desc" class="form-control" />
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-outline">
            <label *ngIf="language=='english'" class="form-label" >Write 3 or 4 keywords related to your title in about 160 characters</label>
                  <label *ngIf="language=='hindi'" class="form-label">3 या 4 कीवर्ड्स अपने टाइटल से संबंधित 160 अक्षर में लिखे?</label>
            <input type="text" formControlName="post_meta_keyword" class="form-control" />
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-outline">
            <label *ngIf="language=='english'" class="form-label" >The link of the post [ *$/?<>& space ] cannot be given, the link of the post should only
              be similar to its title like: <span class="text-bold">what-is-website</span></label>
           <label *ngIf="language=='hindi'" class="form-label">पोस्ट का लिंक [ *$/?>& स्पेस ] नहीं दे सकते है, पोस्ट का लिंक केवल अपने टाइटल से मिलता जुलता होना चाहिए जैसे- 
             <span class="text-bold"> वेबसाइट-क्या-है</span>
           </label>
            <input type="text" formControlName="post_url" class="form-control" pattern="^\w+(?:[]\w+)*(?:\-\w+(?:[]\w+)*)*$"/>
          </div>
          
          <div *ngIf="postArticle.get('post_url')?.['errors']?.['pattern']">
            <p class="text-danger">Follow : Demo Link Title : word-word-word-word : what-is-computer</p>
          </div>
        </div>


       

      </div>
      
      <div class="row">
        <div class="col-md-12">
          <div class="form-outline">
            <label *ngIf="language=='english'" class="form-label" >Select post headline image?</label>
            <label *ngIf="language=='hindi'" class="form-label">पोस्ट हैडलाइन इमेज चुनिए?</label>
            <input type="file" formControlName="post_img" accept=".png" (change)="onSelectedFile($event)" class="form-control" />
            <img width="200" [src]="base64Img" class="mt-2 img-rounded">
          </div>
        </div>
        
      </div>

     

      <div class="form-outline">
        <label *ngIf="language=='english'" class="form-label" >Write post description and share related post link</label>
              <label *ngIf="language=='hindi'" class="form-label">पोस्ट डिस्क्रिप्शन लिखे और रिलेटेड पोस्ट लिंक शेयर कर सकते है। </label>
      
       <angular-editor [config]="config" formControlName="post_desc"></angular-editor> 
      
      </div>



     

      <div class="form-outline">
        <label class="text-bold" *ngIf="language=='english'"> Write related questions and answers from your post? You can take idea from google search engine.</label>
        <label class="text-bold" *ngIf="language=='hindi'"> अपने पोस्ट से रिलेटेड क्वेश्चन और आंसर लिखे? आप गूगल सर्च इंजन से आईडिया  ले सकते है!</label>
        <br/>
        <label *ngIf="language=='english'" class="form-label" >Question No. 1</label>
        <label *ngIf="language=='hindi'" class="form-label">क्वेश्चन नंबर 1</label>
        <input type="text" formControlName="post_question1" class="form-control" />
      </div>

      <div class="form-outline">
        <label *ngIf="language=='english'" class="form-label" >Answer No. 1</label>
        <label *ngIf="language=='hindi'" class="form-label">आंसर नंबर 1</label>
        <input type="text" formControlName="post_answer1" class="form-control" />
      </div>

      <div class="form-outline">
        <label *ngIf="language=='english'" class="form-label" >Question No. 2</label>
        <label *ngIf="language=='hindi'" class="form-label">क्वेश्चन नंबर 2</label>
        <input type="text" formControlName="post_question2" class="form-control" />
      </div>

      <div class="form-outline">
        <label *ngIf="language=='english'" class="form-label" >Answer No. 2</label>
              <label *ngIf="language=='hindi'" class="form-label">आंसर नंबर 2</label>
        <input type="text" formControlName="post_answer2" class="form-control" />
      </div>

      <div class="form-outline">
        <label *ngIf="language=='english'" class="form-label" >Question No.3</label>
              <label *ngIf="language=='hindi'" class="form-label">क्वेश्चन नंबर 3</label>
        <input type="text" formControlName="post_question3" class="form-control" />
       
      </div>

      <div class="form-outline">
        <label *ngIf="language=='english'" class="form-label" >Answer No. 3</label>
              <label *ngIf="language=='hindi'" class="form-label">आंसर नंबर 3</label>
        <input type="text" formControlName="post_answer3" class="form-control" />
      </div>

      <!-- <div class="form-outline hidden-sm hidden-xs hidden-md hidden-lg">
        <label class="form-label">Hour</label>
        <input type="text" formControlName="post_hour" class="form-control" />
      </div> -->
      <div id="captcha">
      </div>
      <div class="form-outline">
        <label *ngIf="language=='english'" class="form-label" >Enter Captch</label>
        <label *ngIf="language=='hindi'" class="form-label">कैप्चा दर्ज करें </label>
        <input type="text" id="cpatchaTextBox" class="form-control"/>
      </div>

      <div class="form-outline hidden-sm hidden-xs hidden-md hidden-lg">
        <label *ngIf="language=='english'" class="form-label" >Author Name</label>
        <label *ngIf="language=='hindi'" class="form-label">ऑथर नाम </label>
        <input type="text" formControlName="post_author" class="form-control"  value="{{author_name()}}"/>
      </div>

      <div class="form-outline hidden-sm hidden-xs hidden-md hidden-lg">
        <label *ngIf="language=='english'" class="form-label" >Author Email</label>
        <label *ngIf="language=='hindi'" class="form-label">ऑथर ईमेल </label>
        <input type="email" formControlName="user_email" class="form-control" value="{{author_email()}}"/>
      </div>
      
    

      <div class="d-flex justify-content-end pt-3 align-items-center">
        <a type="button" class="text-success btn-lg ms-2" routerLink="../postread">See Your Post</a>
        <button type="submit" class="btn btn-success btn-sm ms-2">Post Your Article</button>
      </div>

    </div>
 </form>
  
 <div id="Mymodal" class="modal fade in show">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button class="btn btn-close close" data-bs-dismiss="modal"></button>
      </div>
        <div id="datashow">
          <h2>hi</h2>
        </div>
    </div>
  </div>
 </div> 

  </div>
</div>

<a (click)="startRefresh()" style="color:green;">Get Pop</a>