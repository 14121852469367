import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../footer/footer.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  standalone: true,
  selector: 'app-about',
  imports:[FooterComponent, NavbarComponent,CommonModule],
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit{
  constructor(private titleService:Title, private metaService:Meta){}
  ngOnInit(): void {
    this.titleService.setTitle("About Us - Livenewsinhindi | Informative & Engaging News Content Platform")
    this.metaService.addTags([
       {name:'description', content:'Discover who we are at Livenewsinhindi, your trusted source for diverse and engaging content. Join our vibrant community and explore insightful perspectives across news, health, food, technology, business, and more.'},
       {name: 'author', content:'Admin'},
       {name:"robots", content:"index, follow"}
    ])
   }
}
