import { Component } from '@angular/core';

@Component({
  standalone:true,
  imports:[],
  selector: 'app-breakingnews',
  templateUrl: './breakingnews.component.html',
  styleUrls: ['./breakingnews.component.css']
})
export class BreakingnewsComponent {

}
