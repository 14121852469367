<div class="wrapper">
    <nav id="sidebar" class="sidebar js-sidebar">
      <div class="sidebar-content js-simplebar">
        <a class='sidebar-brand' href='https://livenewsinhindi.com/'>
          <span class="sidebar-brand-text align-middle">
            Livenewsinhindi
            <sup><small class="badge bg-primary text-uppercase">Com</small></sup>
          </span>
          <svg class="sidebar-brand-icon align-middle" width="32px" height="32px" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="1.5"
            stroke-linecap="square" stroke-linejoin="miter" color="#FFFFFF" style="margin-left: -3px">
            <path d="M12 4L20 8.00004L12 12L4 8.00004L12 4Z"></path>
            <path d="M20 12L12 16L4 12"></path>
            <path d="M20 16L12 20L4 16"></path>
          </svg>
        </a>
  
      
  
        <ul class="sidebar-nav">
         
          <!-- <li class="sidebar-item">
            <a data-bs-target="#dashboards" data-bs-toggle="collapse" class="sidebar-link collapsed">
              <i class="align-middle" data-feather="sliders"></i> <span class="align-middle">Dashboards</span>
            </a>
            <ul id="dashboards" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
              <li class="sidebar-item"><a class='sidebar-link' href='index.html'>Analytics</a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='dashboard-ecommerce.html'>E-Commerce <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='dashboard-crypto.html'>Crypto <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
            </ul>
          </li> -->
  
          <li class="sidebar-item active">
            <a data-bs-target="#pages" data-bs-toggle="collapse" class="sidebar-link">
              <i class="align-middle" data-feather="layout"></i> <span class="align-middle">Post</span>
            </a>
            <ul id="pages" class="sidebar-dropdown list-unstyled collapse show" data-bs-parent="#sidebar">
              
              <li class="sidebar-item"><a class='sidebar-link' routerLink='viewpost'>All Posts <span
                class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' routerLink='createpost'>New Post</a></li>
              <!-- <li class="sidebar-item"><a class='sidebar-link' href='pages-clients.html'>Clients <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='pages-orders.html'>Orders <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='pages-pricing.html'>Pricing <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='pages-chat.html'>Chat <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item active"><a class='sidebar-link' href='pages-blank.html'>Blank Page</a></li> -->
            </ul>
          </li>
  
          <li class="sidebar-item">
            <a data-bs-target="#stories" data-bs-toggle="collapse" class="sidebar-link collapsed">
              <i class="align-middle" data-feather="layout"></i> <span class="align-middle">Webstories</span>
            </a>
            <ul id="stories" class="sidebar-dropdown list-unstyled collapse show" data-bs-parent="#sidebar">
              
              <li class="sidebar-item"><a class='sidebar-link' routerLink='webstoriesread'>All <span
                class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' routerLink='webstoriescreate'>Create new</a></li>
             
            </ul>
          </li>
  
          
  <!-- 
          <li class="sidebar-item">
            <a class='sidebar-link' href='pages-profile.html'>
              <i class="align-middle" data-feather="user"></i> <span class="align-middle">Profile</span>
            </a>
          </li>
  
          <li class="sidebar-item">
            <a class='sidebar-link' href='pages-invoice.html'>
              <i class="align-middle" data-feather="credit-card"></i> <span class="align-middle">Invoice</span>
            </a>
          </li>
  
          <li class="sidebar-item">
            <a class='sidebar-link' href='pages-tasks.html'>
              <i class="align-middle" data-feather="list"></i> <span class="align-middle">Tasks</span>
              <span class="sidebar-badge badge bg-primary">Pro</span>
            </a>
          </li>
  
          <li class="sidebar-item">
            <a class='sidebar-link' href='calendar.html'>
              <i class="align-middle" data-feather="calendar"></i> <span class="align-middle">Calendar</span>
              <span class="sidebar-badge badge bg-primary">Pro</span>
            </a>
          </li> -->
  
          <!-- <li class="sidebar-item"> -->
            <!-- <a href="#auth" data-bs-toggle="collapse" class="sidebar-link collapsed">
              <i class="align-middle" data-feather="users"></i> <span class="align-middle">WebStories</span>
            </a>
            <ul id="auth" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
              <li class="sidebar-item"><a class='sidebar-link' href='pages-sign-in.html'>New WebStories</a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='pages-sign-up.html'>View  WebStories</a></li> -->
              <!-- <li class="sidebar-item"><a class='sidebar-link' href='pages-reset-password.html'>Reset Password <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='pages-404.html'>404 Page <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='pages-500.html'>500 Page <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li> -->
            <!-- </ul>
          </li> -->
  
          <li class="sidebar-header">
          Paid  Advertisement
          </li>
          <li class="sidebar-item">
            <a data-bs-target="#ui" data-bs-toggle="collapse" class="sidebar-link collapsed">
              <i class="align-middle" data-feather="briefcase"></i> <span class="align-middle">Ads</span>
            </a>
            <ul id="ui" class="sidebar-dropdown list-unstyled collapse " data-bs-parent="#sidebar">
              <li class="sidebar-item"><a class='sidebar-link' href='#'>New Ads <span
                    class="sidebar-badge badge bg-primary">Pro</span></a></li>
              <li class="sidebar-item"><a class='sidebar-link' href='#'>View Ads</a></li>
             
            </ul>
          </li>
        
  
        
        </ul>
  
        <div class="sidebar-cta">
          <div class="sidebar-cta-content">
            <strong class="d-inline-block mb-2">Go to website</strong>
            <div class="mb-3 text-sm">
              Your weekly sales report is ready for download!
            </div>
  
            <div class="d-grid">
              <a href="#" class="btn btn-outline-primary" target="_blank">Download</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  
    <div class="main">
      <nav class="navbar navbar-expand navbar-light navbar-bg">
        <a class="sidebar-toggle js-sidebar-toggle">
          <i class="hamburger align-self-center"></i>
        </a>
  
        <form class="d-none d-sm-inline-block">
          <div class="input-group input-group-navbar">
            <input type="text" class="form-control" placeholder="Search…" aria-label="Search">
            <button class="btn" type="button">
              <i class="align-middle" data-feather="search"></i>
            </button>
          </div>
        </form>
  
        <ul class="navbar-nav d-none d-lg-flex">
          <li class="nav-item px-2 dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="megaDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              Mega Menu
            </a>
            <div class="dropdown-menu dropdown-menu-start dropdown-mega" aria-labelledby="megaDropdown">
              <div class="d-md-flex align-items-start justify-content-start">
                <div class="dropdown-mega-list">
                  <div class="dropdown-header">UI Elements</div>
                  <a class="dropdown-item" href="#">Alerts</a>
                  <a class="dropdown-item" href="#">Buttons</a>
                  <a class="dropdown-item" href="#">Cards</a>
                  <a class="dropdown-item" href="#">Carousel</a>
                  <a class="dropdown-item" href="#">General</a>
                  <a class="dropdown-item" href="#">Grid</a>
                  <a class="dropdown-item" href="#">Modals</a>
                  <a class="dropdown-item" href="#">Tabs</a>
                  <a class="dropdown-item" href="#">Typography</a>
                </div>
                <div class="dropdown-mega-list">
                  <div class="dropdown-header">Forms</div>
                  <a class="dropdown-item" href="#">Layouts</a>
                  <a class="dropdown-item" href="#">Basic Inputs</a>
                  <a class="dropdown-item" href="#">Input Groups</a>
                  <a class="dropdown-item" href="#">Advanced Inputs</a>
                  <a class="dropdown-item" href="#">Editors</a>
                  <a class="dropdown-item" href="#">Validation</a>
                  <a class="dropdown-item" href="#">Wizard</a>
                </div>
                <div class="dropdown-mega-list">
                  <div class="dropdown-header">Tables</div>
                  <a class="dropdown-item" href="#">Basic Tables</a>
                  <a class="dropdown-item" href="#">Responsive Table</a>
                  <a class="dropdown-item" href="#">Table with Buttons</a>
                  <a class="dropdown-item" href="#">Column Search</a>
                  <a class="dropdown-item" href="#">Muulti Selection</a>
                  <a class="dropdown-item" href="#">Ajax Sourced Data</a>
                </div>
              </div>
            </div>
          </li>
  
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="resourcesDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
              Resources
            </a>
            <div class="dropdown-menu" aria-labelledby="resourcesDropdown">
              <a class="dropdown-item" href="#" target="_blank"><i class="align-middle me-1" data-feather="home"></i>
                Homepage</a>
              <a class="dropdown-item" href="#" target="_blank"><i class="align-middle me-1" data-feather="book-open"></i>
                Documentation</a>
              <a class="dropdown-item" href="#" target="_blank"><i class="align-middle me-1"
                  data-feather="edit"></i> Changelog</a>
            </div>
          </li>
        </ul>
  
        <div class="navbar-collapse collapse">
          <ul class="navbar-nav navbar-align">
            <li class="nav-item dropdown">
              <a class="nav-icon dropdown-toggle" href="#" id="alertsDropdown" data-bs-toggle="dropdown">
                <div class="position-relative">
                  <i class="align-middle" data-feather="bell"></i>
                  <span class="indicator">4</span>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="alertsDropdown">
                <div class="dropdown-menu-header">
                  4 New Notifications
                </div>
                <div class="list-group">
                  <a href="#" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <i class="text-danger" data-feather="alert-circle"></i>
                      </div>
                      <div class="col-10">
                        <div class="text-dark">Update completed</div>
                        <div class="text-muted small mt-1">Restart server 12 to complete the update.</div>
                        <div class="text-muted small mt-1">30m ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <i class="text-warning" data-feather="bell"></i>
                      </div>
                      <div class="col-10">
                        <div class="text-dark">Lorem ipsum</div>
                        <div class="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
                        <div class="text-muted small mt-1">2h ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <i class="text-primary" data-feather="home"></i>
                      </div>
                      <div class="col-10">
                        <div class="text-dark">Login from 192.186.1.8</div>
                        <div class="text-muted small mt-1">5h ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <i class="text-success" data-feather="user-plus"></i>
                      </div>
                      <div class="col-10">
                        <div class="text-dark">New connection</div>
                        <div class="text-muted small mt-1">Christina accepted your request.</div>
                        <div class="text-muted small mt-1">14h ago</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="dropdown-menu-footer">
                  <a href="#" class="text-muted">Show all notifications</a>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-icon dropdown-toggle" href="#" id="messagesDropdown" data-bs-toggle="dropdown">
                <div class="position-relative">
                  <i class="align-middle" data-feather="message-square"></i>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0" aria-labelledby="messagesDropdown">
                <div class="dropdown-menu-header">
                  <div class="position-relative">
                    4 New Messages
                  </div>
                </div>
                <div class="list-group">
                  <a href="#" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <img src="assets/img/avatars/avatar-5.jpg" class="avatar img-fluid rounded-circle" alt="Vanessa Tucker">
                      </div>
                      <div class="col-10 ps-2">
                        <div class="text-dark">Vanessa Tucker</div>
                        <div class="text-muted small mt-1">Nam pretium turpis et arcu. Duis arcu tortor.</div>
                        <div class="text-muted small mt-1">15m ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <img src="assets/img/avatars/avatar-2.jpg" class="avatar img-fluid rounded-circle" alt="William Harris">
                      </div>
                      <div class="col-10 ps-2">
                        <div class="text-dark">William Harris</div>
                        <div class="text-muted small mt-1">Curabitur ligula sapien euismod vitae.</div>
                        <div class="text-muted small mt-1">2h ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <img src="assets/img/avatars/avatar-4.jpg" class="avatar img-fluid rounded-circle" alt="Christina Mason">
                      </div>
                      <div class="col-10 ps-2">
                        <div class="text-dark">Christina Mason</div>
                        <div class="text-muted small mt-1">Pellentesque auctor neque nec urna.</div>
                        <div class="text-muted small mt-1">4h ago</div>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="list-group-item">
                    <div class="row g-0 align-items-center">
                      <div class="col-2">
                        <img src="assets/img/avatars/avatar-3.jpg" class="avatar img-fluid rounded-circle" alt="Sharon Lessman">
                      </div>
                      <div class="col-10 ps-2">
                        <div class="text-dark">Sharon Lessman</div>
                        <div class="text-muted small mt-1">Aenean tellus metus, bibendum sed, posuere ac, mattis non.</div>
                        <div class="text-muted small mt-1">5h ago</div>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="dropdown-menu-footer">
                  <a href="#" class="text-muted">Show all messages</a>
                </div>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-flag dropdown-toggle" href="#" id="languageDropdown" data-bs-toggle="dropdown">
                <img src="assets/img/flags/us.png" alt="English" />
              </a>
              <div class="dropdown-menu dropdown-menu-end" aria-labelledby="languageDropdown">
                <a class="dropdown-item" href="#">
                  <img src="assets/img/flags/us.png" alt="English" width="20" class="align-middle me-1" />
                  <span class="align-middle">English</span>
                </a>
                <a class="dropdown-item" href="#">
                  <img src="assets/img/flags/es.png" alt="Spanish" width="20" class="align-middle me-1" />
                  <span class="align-middle">Spanish</span>
                </a>
                <a class="dropdown-item" href="#">
                  <img src="assets/img/flags/ru.png" alt="Russian" width="20" class="align-middle me-1" />
                  <span class="align-middle">Russian</span>
                </a>
                <a class="dropdown-item" href="#">
                  <img src="assets/img/flags/de.png" alt="German" width="20" class="align-middle me-1" />
                  <span class="align-middle">German</span>
                </a>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-icon js-fullscreen d-none d-lg-block" href="#">
                <div class="position-relative">
                  <i class="align-middle" data-feather="maximize"></i>
                </div>
              </a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-icon pe-md-0 dropdown-toggle" href="#" data-bs-toggle="dropdown">
                <img src="assets/img/avatars/avatar.jpg" class="avatar img-fluid rounded" alt="Charles Hall" />
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <a class='dropdown-item' href='#'><i class="align-middle me-1" data-feather="user"></i> Profile</a>
                <a class="dropdown-item" href="#"><i class="align-middle me-1" data-feather="pie-chart"></i> Analytics</a>
                <div class="dropdown-divider"></div>
                <a class='dropdown-item' href='#'><i class="align-middle me-1" data-feather="settings"></i> Settings &
                  Privacy</a>
                <a class="dropdown-item" href="#"><i class="align-middle me-1" data-feather="help-circle"></i> Help Center</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="logout()">Logout</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
  
      <main class="content">
        <div>
          
         <router-outlet></router-outlet>
              
          
  
        </div>
      </main>
  
      <footer class="footer">
        <div class="container-fluid">
          <div class="row text-muted">
            <div class="col-6 text-start">
              <p class="mb-0">
                <a href="https://livenewsinhindi.com" target="_blank" class="text-muted"><strong>Livenewinhindi.com</strong></a> &copy;
              </p>
            </div>
            <div class="col-6 text-end">
              <ul class="list-inline">
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Support</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Help Center</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Privacy</a>
                </li>
                <li class="list-inline-item">
                  <a class="text-muted" href="#">Terms</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>