import { Component } from '@angular/core';

@Component({
  selector: 'app-verticalbothads',
  standalone: true,
  imports: [],
  templateUrl: './verticalbothads.component.html',
  styleUrl: './verticalbothads.component.css'
})
export class VerticalbothadsComponent {

}
