<div class="widget Blog" data-version="2" id="Blog1">
	<div class="blog-posts item-post-wrap flex-col">
		<article class="blog-post hentry item-post flex-col">
			<div class="item-post-inner flex-col">
				<div class="entry-header p-eh">
					<script type="application/ld+json">{"@context":"http://schema.org","@type":"BreadcrumbList","itemListElement":[{"@type":"ListItem","position":1,"name":"Home","item":"https://webify-templateify.blogspot.com/"},{"@type":"ListItem","position":2,"name":"Contact Us","item":"https://webify-templateify.blogspot.com/p/contact-us.html"}]}</script>
					<h1 class="entry-title">Contact Us</h1> </div>
				<div class="entry-content-wrap flex-col">
					<div class="post-body entry-content" id="post-body">
						<div class="contact-form-widget">
							<form class="contact-form-form" name="contact-form">
								<input ariby="Name" class="contact-form-name cf-s" id="ContactForm1_contact-form-name" name="name" placeholder="Name" size="30" type="text" value="">
								<input ariby="Email *" class="contact-form-email cf-s" id="ContactForm1_contact-form-email" name="email" placeholder="Email *" size="30" type="text" value="">
								<textarea ariby="Message *" class="contact-form-email-message cf-s" cols="25" id="ContactForm1_contact-form-email-message" name="email-message" placeholder="Message *" rows="5"></textarea>
								<input class="contact-form-button btn contact-form-button-submit" id="ContactForm1_contact-form-submit" type="button" value="Send">
								<p class="contact-form-error-message" id="ContactForm1_contact-form-error-message"></p>
								<p class="contact-form-success-message" id="ContactForm1_contact-form-success-message"></p>
							</form>
						</div>
					</div>
				</div>
			</div>
		</article>
	</div>
</div>