<nav class="navbar_area topbar-wrap">
    <div class="container">
        <div class="row">
          <div class="col-md-9">

        
      
        <ul class="navbar-nav-hori me-auto my-2 my-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="/news/stories">Webstories</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/education">Education</a>
          </li>

          <li class="nav-item">
            <a class="nav-link" routerLink="/job">Jobs</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/news">News</a>
          </li>
         
          <li class="nav-item">
            <a class="nav-link" routerLink="/travel">Travel</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/entertainment">Entertainment</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/politics">Politics</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/market">Markets</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/meditation">Meditation</a>
          </li>
         
         
        

         
        </ul>
      </div>
   
    <div class="col-md-3 d-sm-none d-none d-md-block">       
        <ul class="d-flex social-icons social navbar-nav-hori me-auto my-2 my-lg-0 float-end">
         
            <li class="nav-item"> <a class="nav-link" routerLink="about">About Us</a></li>
            <li class="nav-item"> <a class="nav-link">Contact Us</a></li>
            <li class="nav-item"> <a class="nav-link">Youtube</a></li>
        </ul>
      </div>
    </div>
      </div>
   
  </nav>