<nav class="navbar_area topbar-wrap">
    <div class="container">
        <div class="row">
          <div class="col-md-12">

        
      
        <ul class="navbar-nav-hori me-auto my-2 my-lg-0 justify-content-center">
          <li class="nav-item">
            <a class="nav-link" routerLink="/health">Health</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/food">Food</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/science">Science</a>
          </li>
         
          <li class="nav-item">
            <a class="nav-link" routerLink="/lifestyle">Life Style</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/climate">Climate</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/sport">Sports</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/technology">Technology</a>
          </li>
          
          <li class="nav-item">
            <a class="nav-link" routerLink="/business">Business</a>
          </li>
        
          <li class="nav-item">
            <a class="nav-link" routerLink="/website">Website News</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/moviesong">Top Trends Movie & Song</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/blog">Blog</a>
          </li>

        

         
        </ul>
      </div>
   
    
    </div>
      </div>
   
  </nav>